import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'
import {
  Campaign,
  CampaignPathParams,
  CampaignRecipients,
  CampaignContactsMapping,
  CampaignsContactsValidation
} from '@/services/types/OneOffCampaign'

interface IOneoffCampaignService {
  $axios: NuxtAxiosInstance
  getCampaigns: (tenantId: string, phoneNumberId: string) => Promise<Campaign[]>
  getCampaign: ({ tenantId, phoneNumberId, campaignId }: CampaignPathParams) => Promise<Campaign>
  getRecipients: ({ tenantId, phoneNumberId, campaignId }: CampaignPathParams) => Promise<CampaignRecipients>
  updateCampaignStatus: ({ tenantId, phoneNumberId, campaignId }: CampaignPathParams, data: Pick<Campaign, 'status'>) => Promise<Campaign>
  preValidateContacts: (
    tenantId: string,
    phoneNumberId: string,
    campaignContactsMapping: CampaignContactsMapping
  ) => Promise<CampaignsContactsValidation>
  save: (tenantId: string, phoneNumberId: string, campaign: Campaign) => Promise<Campaign>
}

export default class OneoffCampaignService implements IOneoffCampaignService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  public getCampaigns (tenantId: string, phoneNumberId: string) {
    return this.$axios.$get(`/api/sms-campaigns/${tenantId}/${phoneNumberId}`)
  }

  public getCampaign ({ tenantId, phoneNumberId, campaignId }: CampaignPathParams) {
    return this.$axios.$get(`/api/sms-campaigns/${tenantId}/${phoneNumberId}/${campaignId}`)
  }

  public getRecipients ({ tenantId, phoneNumberId, campaignId }: CampaignPathParams) {
    return this.$axios.$get(`/api/sms-campaigns/${tenantId}/${phoneNumberId}/${campaignId}/candidates`)
  }

  public updateCampaignStatus ({ tenantId, phoneNumberId, campaignId }: CampaignPathParams, data: Pick<Campaign, 'status'>) {
    return this.$axios.$patch(`/api/sms-campaigns/${tenantId}/${phoneNumberId}/${campaignId}`, data)
  }

  public preValidateContacts (
    tenantId: string,
    phoneNumberId: string,
    campaignContactsMapping: CampaignContactsMapping
  ) {
    return this.$axios.post(
      `/api/sms-campaigns/${tenantId}/${phoneNumberId}/validate-import`, campaignContactsMapping
    ) as Promise<CampaignsContactsValidation>
  }

  public save (tenantId: string, phoneNumberId: string, campaign: Campaign) {
    return this.$axios.$post(`/api/sms-campaigns/${tenantId}/${phoneNumberId}`, campaign)
  }
}
